import { Mutation } from "@apollo/client/react/components"
import fetch from "cross-fetch"
import { Form, FormikErrors, withFormik } from "formik"
import React from "react"
import styled from "styled-components/macro"
import { extractValidationError } from "../../../../common/apiErrors"
import AnalyticScreen from "../../../components/AnalyticsScreen"
import { ButtonBase, ButtonSmallBase } from "../../../components/Button"
import { FormButtonSpinnerBase, FormErrors, FormSuccessStatus } from "../../../components/Form"
import Spinner from "../../../components/Spinner"
import Analytics from "../../../utils/analytics"
import { palette, pxToRem } from "../../../utils/style-utils"
import { FormP } from "../../PoolSetupPages/styles/CommonPoolSetup.styles"
import { GET_ENTRY_AVATAR_SIGNED_URL_MUTATION } from "../../queries"

import { GetEntryAvatarSignedUrlMutation, GetEntryAvatarSignedUrlMutationVariables } from "../../../../__generated__/GetEntryAvatarSignedUrlMutation"
import Cropper from "../../../components/Cropper"
const analyticPrefix = "avatar entry"

const StyledForm = styled.form`
  max-width: 100%;
  min-height: ${pxToRem(400)};
  background-color: #fff;
  margin: 0 auto;

  @media (min-width: ${pxToRem(800)}) {
    & {
      min-width: ${pxToRem(600)};
    }
  }

  & .preview {
    display: block;
    width: ${pxToRem(80)};
    height: ${pxToRem(80)};
    margin: 0 auto;
  }

  & .upload-area {
    height: 40vh;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }

  & .cropper {
    max-height: 40vh;
    width: 100%;
  }

  & .form-group {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  & .actions {
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    & > * {
      white-space: nowrap;
    }
  }
  & .preview-container {
    & div {
      text-align: center;
      padding-top: 0.5em;
    }
  }

  & [type="file"] {
    display: none;
  }

  img {
    max-width: 100%;
  }
  & .cropper-view-box,
  & .cropper-face {
    border-radius: 50%;
  }
`
const SpinnerContainer = styled.div`
  svg {
    width: 3rem;
    height: 3rem;
  }
`

function getRoundedCanvas(sourceCanvas) {
  const canvas = document.createElement("canvas")
  const context = canvas.getContext("2d")!
  const width = sourceCanvas.width
  const height = sourceCanvas.height
  canvas.width = width
  canvas.height = height
  context.imageSmoothingEnabled = true
  context.drawImage(sourceCanvas, 0, 0, width, height)
  context.globalCompositeOperation = "destination-in"
  context.beginPath()
  context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true)
  context.fill()
  return canvas
}

class Thumb extends React.PureComponent<any, any> {
  public state = {
    loading: false,
    thumb: undefined,
  }

  public rafId: any = null
  public cropper: any = null

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.file && nextProps.file !== this.props.file) {
      this.setState({ loading: true }, () => {
        const reader = new FileReader()
        reader.onloadend = () => {
          this.setState({ loading: false, thumb: reader.result })
        }
        reader.readAsDataURL(nextProps.file)
      })
    }
  }
  public processCrop = () => {
    this.rafId = null
    // console.log(`processCrop`);
    if (this.cropper) {
      const croppedCanvas = this.cropper.getCroppedCanvas()
      const roundedCanvas = getRoundedCanvas(croppedCanvas)
      // Show
      const newSrc = roundedCanvas.toDataURL()
      this.props.onCroppedSourceChange(newSrc || null)
    }
  }

  public onCrop = () => {
    // console.log(`onCrop`);
    // console.dir(this.cropper);
    if (!this.rafId) {
      this.rafId = window.requestAnimationFrame(this.processCrop)
    }
  }

  public onRef = (ref) => (this.cropper = ref)

  public render() {
    const { file } = this.props
    const { loading, thumb } = this.state
    if (!file) {
      return null
    }
    if (loading) {
      return (
        <SpinnerContainer>
          <Spinner color={palette.blue1} />
        </SpinnerContainer>
      )
    }
    return (
      <Cropper
        className="cropper"
        ref={this.onRef}
        modal={true}
        src={thumb}
        minCropBoxWidth={60}
        guides={false}
        crop={this.onCrop}
        checkCrossOrigin={false}
        crossOrigin="anonymous"
        aspectRatio={1}
        viewMode={1}
      />
    )
  }
}

class EntryAvatarFormComponent extends React.PureComponent<any> {
  public onFileChange = (event) => {
    this.props.setFieldValue("file", (event.currentTarget.files && event.currentTarget.files[0]) || null)
  }

  public onCroppedSourceChange = (newSrc) => {
    // console.log(`onCroppedSourceChange`);
    this.props.setFieldValue("croppedSource", newSrc || null)
  }

  public render() {
    const props = this.props
    // console.dir(props);
    const { values, isSubmitting, isValid, status, entry, errors, isCbsAppWebview } = props
    const hasUpload = !!values.file
    const ctaPrompt = isCbsAppWebview ? `Visit the web version to change your avatar` : `Upload an image you would like to make your new avatar.`
    return (
      <StyledForm as={Form}>
        <AnalyticScreen feature="options" subfeature={`entry avatar`} title={`Settings - Entry Avatar`} isModal={true} />
        <div className="upload-area">
          <Thumb file={values.file} onCroppedSourceChange={this.onCroppedSourceChange} />
          {!hasUpload && <FormP>{ctaPrompt}</FormP>}
          {!hasUpload && !isCbsAppWebview && (
            <ButtonBase as="label" htmlFor="file">
              Select an image
            </ButtonBase>
          )}
        </div>
        <FormErrors errors={errors} submitCount={1} />
        {hasUpload && (
          <div className="form-group">
            <div className="actions">
              <ButtonSmallBase as="label" htmlFor="file">
                Change Image
              </ButtonSmallBase>
              <FormButtonSpinnerBase
                as={ButtonSmallBase}
                success="Uploaded!"
                type="submit"
                inert="Upload"
                status={status}
                isSubmitting={isSubmitting}
                isValid={isValid}
              />
            </div>
            <div className="preview-container">
              <img className="preview" src={values.croppedSource || entry.avatarUrl} alt="Preview" />
              <div>Preview</div>
            </div>
          </div>
        )}
        <input id="file" name="file" type="file" multiple={false} accept="image/*" onChange={this.onFileChange} />
      </StyledForm>
    )
  }
}

const uploadAvatarFormikOptions = {
  mapPropsToValues: (props) => {
    return {
      file: null,
      croppedSource: null,
    }
  },

  validate: (values) => {
    const errors: FormikErrors<any> = {}
    const file = values.file
    if (!file) {
      errors.file = "Please upload an image"
    } else {
      // 2MB max
      if (file.size > 2097152) {
        errors.file = "Image too large (must be under 2MB)"
      }
      if (!/image\//.test(file.type)) {
        errors.file = `File must be an image (received: ${file.type})`
      }
    }
    return errors
  },

  handleSubmit: async (formVariables, actions) => {
    // console.log(`formVariables:`)
    // console.dir(formVariables)
    // console.log(`actions:`)
    // console.dir(actions)
    const { mutation, close, entry, periodId, upsertEntryMutation } = actions.props
    // const {name, type} = formVariables.file
    const variables = {
      entryId: entry.id,
      fileName: `avatar.png`,
      fileType: "image/png",
    }
    Analytics.trackInteraction(`${analyticPrefix} - submit`)
    try {
      const signedUrlResp = await mutation({ variables })
      const { signedRequest, url } = signedUrlResp.data.getEntryAvatarSignedUrl
      // console.debug(`signedRequest: ${signedRequest}`)
      const headers = {
        "Content-Type": variables.fileType,
      }
      const base64String = formVariables.croppedSource
      const byteString = window.atob(base64String.split(",")[1])
      // write the bytes of the string to an ArrayBuffer
      const ab = new ArrayBuffer(byteString.length)
      // create a view into the buffer
      const ia = new Uint8Array(ab)
      // set the bytes of the buffer to the correct values
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }
      const blobBody = new Blob([ia], { type: "image/png" })
      // const blobBody2 = await fetch(base64String).then(res => res.blob())
      const s3Response = await fetch(signedRequest, {
        method: "PUT",
        body: blobBody,
        headers,
      })
      const textResponse = await s3Response.text()
      if (!s3Response.ok) {
        throw new Error(textResponse)
      }
      const variables2 = {
        entryId: entry.id,
        avatarUrl: url,
        periodId,
      }
      await upsertEntryMutation({ variables: variables2 })
      // console.log(`success:`, upsertResponse)
      actions.setStatus(FormSuccessStatus)
      actions.setSubmitting(false)
      setTimeout(close, 900)
    } catch (err) {
      const apiErrors = extractValidationError(err)
      actions.setErrors(apiErrors.errors)
      actions.setSubmitting(false)
      Analytics.trackInteraction(`${analyticPrefix} - fail`)
    }
  },
}

export const EntryAvatarFormWForm = withFormik(uploadAvatarFormikOptions)(EntryAvatarFormComponent)

const EntryAvatarForm = (props) => {
  return (
    <Mutation<GetEntryAvatarSignedUrlMutation, GetEntryAvatarSignedUrlMutationVariables> mutation={GET_ENTRY_AVATAR_SIGNED_URL_MUTATION}>
      {(mutation, { loading }) => <EntryAvatarFormWForm {...props} mutation={mutation} loading={loading} />}
    </Mutation>
  )
}
export default EntryAvatarForm
