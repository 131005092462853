import Cropper from "cropperjs"
// import "cropperjs";
// import "cropperjs/dist/cropper.css";
import React from "react"
import styled from "styled-components/macro"

// tslint:disable-next-line: max-line-length
const bg =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC"
const WithStyles = styled.div`
  .cropper-container {
    direction: ltr;
    font-size: 0;
    line-height: 0;
    overflow: hidden;
    position: relative;
    -ms-touch-action: none;
    touch-action: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .cropper-container img {
    display: block;
    height: 100%;
    max-height: none !important;
    max-width: none !important;
    min-height: 0 !important;
    min-width: 0 !important;
    width: 100%;
  }

  .cropper-canvas,
  .cropper-crop-box,
  .cropper-drag-box {
    left: 0;
    position: absolute;
    top: 0;
  }

  .cropper-drag-box {
    background-color: #fff;
    bottom: 0;
    opacity: 0;
    right: 0;
  }

  .cropper-modal {
    background-color: #000;
    opacity: 0.5;
  }

  .cropper-view-box {
    height: 100%;
    outline: 1px solid #39f;
    outline-color: rgba(51, 153, 255, 0.75);
    overflow: hidden;
    width: 100%;
  }

  .cropper-dashed {
    border: 0 dashed #eee;
    opacity: 0.5;
    position: absolute;
  }

  .cropper-dashed.dashed-h {
    border-bottom-width: 1px;
    border-top-width: 1px;
    height: 33.33333%;
    left: 0;
    top: 33.33333%;
    width: 100%;
  }

  .cropper-dashed.dashed-v {
    border-left-width: 1px;
    border-right-width: 1px;
    height: 100%;
    left: 33.33333%;
    top: 0;
    width: 33.33333%;
  }

  .cropper-center {
    height: 0;
    left: 50%;
    opacity: 0.75;
    position: absolute;
    top: 50%;
    width: 0;
  }

  .cropper-center:after,
  .cropper-center:before {
    background-color: #eee;
    content: "";
    display: block;
    position: absolute;
  }

  .cropper-center:before {
    height: 1px;
    left: -3px;
    top: 0;
    width: 7px;
  }

  .cropper-center:after {
    height: 7px;
    left: 0;
    top: -3px;
    width: 1px;
  }

  .cropper-face,
  .cropper-line,
  .cropper-point {
    height: 100%;
    opacity: 0.1;
    position: absolute;
    width: 100%;
  }

  .cropper-face {
    background-color: #fff;
    left: 0;
    top: 0;
  }

  .cropper-line {
    background-color: #39f;
  }

  .cropper-line.line-e {
    cursor: ew-resize;
    right: -3px;
    top: 0;
    width: 5px;
  }

  .cropper-line.line-n {
    cursor: ns-resize;
    height: 5px;
    left: 0;
    top: -3px;
  }

  .cropper-line.line-w {
    cursor: ew-resize;
    left: -3px;
    top: 0;
    width: 5px;
  }

  .cropper-line.line-s {
    bottom: -3px;
    cursor: ns-resize;
    height: 5px;
    left: 0;
  }

  .cropper-point {
    background-color: #39f;
    height: 5px;
    opacity: 0.75;
    width: 5px;
  }

  .cropper-point.point-e {
    cursor: ew-resize;
    margin-top: -3px;
    right: -3px;
    top: 50%;
  }

  .cropper-point.point-n {
    cursor: ns-resize;
    left: 50%;
    margin-left: -3px;
    top: -3px;
  }

  .cropper-point.point-w {
    cursor: ew-resize;
    left: -3px;
    margin-top: -3px;
    top: 50%;
  }

  .cropper-point.point-s {
    bottom: -3px;
    cursor: s-resize;
    left: 50%;
    margin-left: -3px;
  }

  .cropper-point.point-ne {
    cursor: nesw-resize;
    right: -3px;
    top: -3px;
  }

  .cropper-point.point-nw {
    cursor: nwse-resize;
    left: -3px;
    top: -3px;
  }

  .cropper-point.point-sw {
    bottom: -3px;
    cursor: nesw-resize;
    left: -3px;
  }

  .cropper-point.point-se {
    bottom: -3px;
    cursor: nwse-resize;
    height: 20px;
    opacity: 1;
    right: -3px;
    width: 20px;
  }

  @media (min-width: 768px) {
    .cropper-point.point-se {
      height: 15px;
      width: 15px;
    }
  }

  @media (min-width: 992px) {
    .cropper-point.point-se {
      height: 10px;
      width: 10px;
    }
  }

  @media (min-width: 1200px) {
    .cropper-point.point-se {
      height: 5px;
      opacity: 0.75;
      width: 5px;
    }
  }

  .cropper-point.point-se:before {
    background-color: #39f;
    bottom: -50%;
    content: "";
    display: block;
    height: 200%;
    opacity: 0;
    position: absolute;
    right: -50%;
    width: 200%;
  }

  .cropper-invisible {
    opacity: 0;
  }

  .cropper-bg {
    background-image: url("${bg}");
  }

  .cropper-hide {
    display: block;
    height: 0;
    position: absolute;
    width: 0;
  }

  .cropper-hidden {
    display: none !important;
  }

  .cropper-move {
    cursor: move;
  }

  .cropper-crop {
    cursor: crosshair;
  }

  .cropper-disabled .cropper-drag-box,
  .cropper-disabled .cropper-face,
  .cropper-disabled .cropper-line,
  .cropper-disabled .cropper-point {
    cursor: not-allowed;
  }
`

//

const optionProps = [
  "dragMode",
  "aspectRatio",
  "data",
  "crop",
  // unchangeable props start from here
  "viewMode",
  "preview",
  "responsive",
  "restore",
  "checkCrossOrigin",
  "checkOrientation",
  "modal",
  "guides",
  "center",
  "highlight",
  "background",
  "autoCrop",
  "autoCropArea",
  "movable",
  "rotatable",
  "scalable",
  "zoomable",
  "zoomOnTouch",
  "zoomOnWheel",
  "wheelZoomRatio",
  "cropBoxMovable",
  "cropBoxResizable",
  "toggleDragModeOnDblclick",
  "minContainerWidth",
  "minContainerHeight",
  "minCanvasWidth",
  "minCanvasHeight",
  "minCropBoxWidth",
  "minCropBoxHeight",
  "ready",
  "cropstart",
  "cropmove",
  "cropend",
  "zoom",
]

const unchangeableProps = optionProps.slice(4)

class ReactCropper extends React.PureComponent<any> {
  public static defaultProps = {
    src: null,
    dragMode: "crop",
    data: null,
    scaleX: 1,
    scaleY: 1,
    enable: true,
    zoomTo: 1,
    rotateTo: 0,
  }

  public cropper: any = null
  public img: null | undefined = null

  public componentDidMount() {
    const options = Object.keys(this.props)
      .filter((propKey) => optionProps.indexOf(propKey) !== -1)
      .reduce((prevOptions, propKey) => Object.assign({}, prevOptions, { [propKey]: this.props[propKey] }), {})
    this.cropper = new Cropper(this.img!, options)
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.src !== this.props.src) {
      this.cropper.reset().clear().replace(nextProps.src)
    }
    if (nextProps.aspectRatio !== this.props.aspectRatio) {
      this.setAspectRatio(nextProps.aspectRatio)
    }
    if (nextProps.data !== this.props.data) {
      this.setData(nextProps.data)
    }
    if (nextProps.dragMode !== this.props.dragMode) {
      this.setDragMode(nextProps.dragMode)
    }
    if (nextProps.cropBoxData !== this.props.cropBoxData) {
      this.setCropBoxData(nextProps.cropBoxData)
    }
    if (nextProps.canvasData !== this.props.canvasData) {
      this.setCanvasData(nextProps.canvasData)
    }
    if (nextProps.moveTo !== this.props.moveTo) {
      if (nextProps.moveTo.length > 1) {
        this.moveTo(nextProps.moveTo[0], nextProps.moveTo[1])
      } else {
        this.moveTo(nextProps.moveTo[0])
      }
    }
    if (nextProps.zoomTo !== this.props.zoomTo) {
      this.zoomTo(nextProps.zoomTo)
    }
    if (nextProps.rotateTo !== this.props.rotateTo) {
      this.rotateTo(nextProps.rotateTo)
    }
    if (nextProps.scaleX !== this.props.scaleX) {
      this.scaleX(nextProps.scaleX)
    }
    if (nextProps.scaleY !== this.props.scaleY) {
      this.scaleY(nextProps.scaleY)
    }
    if (nextProps.enable !== this.props.enable) {
      if (nextProps.enable) {
        this.enable()
      } else {
        this.disable()
      }
    }

    Object.keys(nextProps).forEach((propKey) => {
      let isDifferentVal = nextProps[propKey] !== this.props[propKey]
      const isUnchangeableProps = unchangeableProps.indexOf(propKey) !== -1

      if (typeof nextProps[propKey] === "function" && typeof this.props[propKey] === "function") {
        isDifferentVal = nextProps[propKey].toString() !== this.props[propKey].toString()
      }

      if (isDifferentVal && isUnchangeableProps) {
        throw new Error(`prop: ${propKey} can't be change after componentDidMount`)
      }
    })
  }

  public componentWillUnmount() {
    if (this.img && this.cropper) {
      // Destroy the cropper, this makes sure events such as resize are cleaned up and do not leak
      this.cropper.destroy()
      delete this.img
      delete this.cropper
    }
  }

  public setDragMode(mode) {
    return this.cropper.setDragMode(mode)
  }

  public setAspectRatio(aspectRatio) {
    return this.cropper.setAspectRatio(aspectRatio)
  }

  public getCroppedCanvas(options) {
    return this.cropper.getCroppedCanvas(options)
  }

  public setCropBoxData(data) {
    return this.cropper.setCropBoxData(data)
  }

  public getCropBoxData() {
    return this.cropper.getCropBoxData()
  }

  public setCanvasData(data) {
    return this.cropper.setCanvasData(data)
  }

  public getCanvasData() {
    return this.cropper.getCanvasData()
  }

  public getImageData() {
    return this.cropper.getImageData()
  }

  public getContainerData() {
    return this.cropper.getContainerData()
  }

  public setData(data) {
    return this.cropper.setData(data)
  }

  public getData(rounded) {
    return this.cropper.getData(rounded)
  }

  public crop() {
    return this.cropper.crop()
  }

  public move(offsetX, offsetY) {
    return this.cropper.move(offsetX, offsetY)
  }

  public moveTo(x: number, y?: number) {
    return this.cropper.moveTo(x, y)
  }

  public zoom(ratio) {
    return this.cropper.zoom(ratio)
  }

  public zoomTo(ratio) {
    return this.cropper.zoomTo(ratio)
  }

  public rotate(degree) {
    return this.cropper.rotate(degree)
  }

  public rotateTo(degree) {
    return this.cropper.rotateTo(degree)
  }

  public enable() {
    return this.cropper.enable()
  }

  public disable() {
    return this.cropper.disable()
  }

  public reset() {
    return this.cropper.reset()
  }

  public clear() {
    return this.cropper.clear()
  }

  public replace(url, onlyColorChanged) {
    return this.cropper.replace(url, onlyColorChanged)
  }

  public scale(scaleX, scaleY) {
    return this.cropper.scale(scaleX, scaleY)
  }

  public scaleX(scaleX) {
    return this.cropper.scaleX(scaleX)
  }

  public scaleY(scaleY) {
    return this.cropper.scaleY(scaleY)
  }

  public bindImg = (ref) => (this.img = ref)

  public render() {
    const { src, alt, crossOrigin, style, className } = this.props

    return (
      <WithStyles style={style} className={className}>
        <img crossOrigin={crossOrigin} ref={this.bindImg} src={src} alt={alt === undefined ? "picture" : alt} style={{ opacity: 0 }} />
      </WithStyles>
    )
  }
}
//
// ReactCropper.propTypes = {
//   style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
//   className: PropTypes.string,
//
//   // react cropper options
//   crossOrigin: PropTypes.string,
//   src: PropTypes.string,
//   alt: PropTypes.string,
//
//   // props of option can be changed after componentDidmount
//   aspectRatio: PropTypes.number,
//   dragMode: PropTypes.oneOf(['crop', 'move', 'none']),
//   data: PropTypes.shape({
//     x: PropTypes.number,
//     y: PropTypes.number,
//     width: PropTypes.number,
//     height: PropTypes.number,
//     rotate: PropTypes.number,
//     scaleX: PropTypes.number,
//     scaleY: PropTypes.number,
//   }),
//   scaleX: PropTypes.number,
//   scaleY: PropTypes.number,
//   enable: PropTypes.bool,
//   cropBoxData: PropTypes.shape({
//     left: PropTypes.number,
//     top: PropTypes.number,
//     width: PropTypes.number,
//     height: PropTypes.number,
//   }),
//   canvasData: PropTypes.shape({
//     left: PropTypes.number,
//     top: PropTypes.number,
//     width: PropTypes.number,
//     height: PropTypes.number,
//   }),
//   zoomTo: PropTypes.number,
//   moveTo: PropTypes.arrayOf(PropTypes.number),
//   rotateTo: PropTypes.number,
//
//   // cropperjs options
//   // https://github.com/fengyuanchen/cropperjs#options
//   // aspectRatio, dragMode, data
//   viewMode: PropTypes.oneOf([0, 1, 2, 3]),
//   preview: PropTypes.string,
//   responsive: PropTypes.bool,
//   restore: PropTypes.bool,
//   checkCrossOrigin: PropTypes.bool,
//   checkOrientation: PropTypes.bool,
//   modal: PropTypes.bool,
//   guides: PropTypes.bool,
//   center: PropTypes.bool,
//   highlight: PropTypes.bool,
//   background: PropTypes.bool,
//   autoCrop: PropTypes.bool,
//   autoCropArea: PropTypes.number,
//   movable: PropTypes.bool,
//   rotatable: PropTypes.bool,
//   scalable: PropTypes.bool,
//   zoomable: PropTypes.bool,
//   zoomOnTouch: PropTypes.bool,
//   zoomOnWheel: PropTypes.bool,
//   wheelZoomRatio: PropTypes.number,
//   cropBoxMovable: PropTypes.bool,
//   cropBoxResizable: PropTypes.bool,
//   toggleDragModeOnDblclick: PropTypes.bool,
//   minContainerWidth: PropTypes.number,
//   minContainerHeight: PropTypes.number,
//   minCanvasWidth: PropTypes.number,
//   minCanvasHeight: PropTypes.number,
//   minCropBoxWidth: PropTypes.number,
//   minCropBoxHeight: PropTypes.number,
//   ready: PropTypes.func,
//   cropstart: PropTypes.func,
//   cropmove: PropTypes.func,
//   cropend: PropTypes.func,
//   crop: PropTypes.func,
//   zoom: PropTypes.func,
// };

export default ReactCropper
